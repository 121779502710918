/* social module */
(function(){
	if(typeof FOCheader === "undefined") { FOCheader = {}; }

	var overlay_social;

	function init(params){
		var overlay_social = document.getElementById("foc-header-overlay-social");

		if(typeof params !== "undefined" && params.length > 0){
			var social_links = getSocialLinks(params);
			if(social_links != ""){
				overlay_social.innerHTML = social_links;
				overlay_social.classList.remove("foc-header-hidden");
			}
		}
	}

	function getSocialLinks(social_list) {
		var list = social_list,
			links = "";

		for (var i = 0; i < list.length; i++) {
			var icon = '';
			switch(list[i].className){
				case "foc-header-facebook":
					icon = '<span class="foc-icon"><svg viewBox="0 0 60 60"><use xlink:href="/css/images/icons.svg#footer-social-FB" /></svg></span>Facebook';
				break;

				case "foc-header-youtube":
					icon = '<span class="foc-icon"><svg viewBox="0 0 60 60"><use xlink:href="/css/images/icons.svg#footer-social-YT" /></svg></span>YouTube';
				break;

				case "foc-header-instagram":
					icon = '<span class="foc-icon"><svg viewBox="0 0 60 60"><use xlink:href="/css/images/icons.svg#footer-social-IG" /></svg></span>Instagram';
				break;

				default:
				break;
			}

			links += '<a href="'+list[i].url+'" class="foc-social-channel '+list[i].className+'" title="'+list[i].name+'" target="_blank" rel="nofollow">' + icon + '</a>';
		}

		return links;
	}

	FOCheader.social = init;
}());

/* calcNavigationWidth module */
(function(){
	if(typeof FOCheader === "undefined") { FOCheader = {}; }

	var nav,
		nav_ul,
		nav_overflow_menu;

	function init(){
		nav = document.getElementById("foc-header-nav");
		nav_overflow_menu = document.getElementById("foc-header-nav-overflow-menu");
		nav_ul = nav.querySelector(".foc-header-nav > ul");

		nav_ul.style.width = "auto";
		toggleOverflowMenu();

		window.addEventListener('scroll', toggleOverflowMenu, false);
		window.addEventListener('resize', toggleOverflowMenu, false);

	}

	function toggleOverflowMenu(){
		if(nav.offsetWidth == (nav_ul.offsetWidth + 60)){
			nav_overflow_menu.classList.remove("foc-header-hidden");
		} else {
			nav_overflow_menu.classList.add("foc-header-hidden");
		}
	}

	FOCheader.calcNavigationWidth = init;
}());

/* populateOverlay module */
(function(){
	if(typeof FOCheader === "undefined") { FOCheader = {}; }

	var prefix = "foc-header-",
		el,
		overlay_el;

	function init(el_name,overlay_el_name){
		el = document.getElementById(prefix + el_name);
		overlay_el = document.getElementById(prefix + overlay_el_name);
		populate();
	}

	function populate(){
		if(el && overlay_el){
			overlay_el.innerHTML = el.innerHTML;
		}
	}

	FOCheader.populateOverlay = init;
}());

/* hideComponent module */
(function(){
	if(typeof FOCheader === "undefined") { FOCheader = {}; }

	var prefix = "foc-header-",
		el;

	function init(el_name){
		el = document.getElementById(prefix + el_name);
		hide();
	}

	function hide(){
		if(el){
			el.classList.add("foc-header-hidden");
		}
	}

	FOCheader.hideComponent = init;
}());

/* menuManager module */
(function(){
	if(typeof FOCheader === "undefined") { FOCheader = {}; }

	function init(){
        if(document.getElementById("foc-header-nav")){
            checkSelected("nav");
        }
		checkSelected("overlay-nav");
	}

	function checkSelected(navType){
		var nav = document.getElementById("foc-header-" + navType);
		var selectedElements = nav.querySelectorAll(".selected");

		if(selectedElements.length > 0){
			var lastOfSelected = selectedElements[selectedElements.length-1];

			for (var i = (navType == "nav") ? 1 : 0; i < selectedElements.length; i++) {
				if(selectedElements[i] != lastOfSelected && selectedElements[i].classList.contains("selected")){
					selectedElements[i].classList.remove("selected");
				}
			}
		}
	}

	FOCheader.menuManager = init;
}());

/* stickyHeader module */
(function(){
	if(typeof FOCheader === "undefined") { FOCheader = {}; }

	var body,
		header,
		nav,
		secondLevelNavs,
		strip_adv,
		pamela,
		stuck;

	function init(){
		body = document.getElementsByTagName("body")[0];
		header = document.getElementById("foc-header");
		nav = document.getElementById("foc-header-nav");
		strip_adv = document.getElementById("strip_adv");
		pamela = document.getElementById("av-pushdown-wrapper");
		stuck = false;

		window.addEventListener("scroll", handleSticky);
	}

	function getOffsetTop(){
		var offset = 0;

		return offset;
	}

	function handleSticky(){
		var stick_point = getOffsetTop() + strip_adv.offsetHeight + pamela.offsetHeight;
		var distance = stick_point - window.pageYOffset;
		var offset = window.pageYOffset;

		if(!body.classList.contains("pushing")){
			if (distance <= 0 && !stuck) {
				body.classList.add("foc-sticky");
				header.classList.add("foc-header-sticky");
				stuck = true;
			} else if (stuck && (offset <= stick_point)){
				body.classList.remove("foc-sticky");
				header.classList.remove("foc-header-sticky");
				stuck = false;
			}
		}
	}

	FOCheader.stickyHeader = init;
}());

/* handlers module */
(function(){
	if(typeof FOCheader === "undefined") { FOCheader = {}; }

	var body,
		html,
		hamburger,
		close_button,
		close_panel,
		open_menu,
		overlay_container,
		nav_overflow_menu,
		pageYOffset;

	function init(){
		html = document.getElementsByTagName("html")[0];
		body = document.getElementsByTagName("body")[0];
		hamburger = document.getElementById("foc-header-hamburger");
		open_menu = document.getElementsByClassName("foc-header-open-menu");
		close_button = document.getElementById("foc-header-overlay-close");
		close_panel = document.getElementById("foc-header-overlay-close-panel");
		
		header_nav_ellipsis = document.querySelector(".foc-header-nav-ellipsis");

		bindHandlers();
	}

	function bindHandlers(){
		hamburger.addEventListener("click", toggleOverlay, false);
		for (var i = 0; i < open_menu.length; i++) {
			open_menu[i].addEventListener("click", toggleOverlay, false);
		}
		close_button.addEventListener("click", toggleOverlay, false);
		close_panel.addEventListener("click", hideOverlay, false);

		header_nav_ellipsis.addEventListener("click", toggleOverlay, false);
	}

	function toggleOverlay(ev){
		ev.preventDefault();
		ev.stopImmediatePropagation();
		if(body.classList.contains("foc-header-overlay-opened")){
			body.classList.remove("foc-header-overlay-opened");
			window.scrollTo(0,pageYOffset);
			document.removeEventListener("touchmove", preventScroll, false);
		} else {
			pageYOffset = window.pageYOffset;
			body.classList.add("foc-header-overlay-opened");
			document.addEventListener("touchmove", preventScroll, false);
		}
	}

	function preventScroll(ev){
	    if(!findParentByClassName(ev.target, "foc-header-overlay-opened")){
	    	ev.preventDefault();
	    }
	}

	function findParentByClassName(el,className) {
    	while (el.parentNode && el.parentElement !== html) {
        	el = el.parentNode;
        	if (el.classList.contains(className)){
            	return el;
        	}
    	}
    	return null;
	}

	function hideOverlay(ev){
		if(body.classList.contains("foc-header-overlay-opened")){
			body.classList.remove("foc-header-overlay-opened");
			document.removeEventListener("touchmove", preventScroll, false);
		}
	}

	FOCheader.handlers = init;
}());

/*
Inizializzazione FOCheader
- popolamento logo
- popolamento del menù di navigazione presente nell'overlay
- gestione del funzionamento del menù
- gestione sticky header allo scroll
- bind eventi generici
*/
(function(o){
	if(typeof o === "undefined") { return; }
	var FOCheaderOptions = JSON.parse(JSON.stringify(o));

    FOCheader.menuManager();
	// FOCheader.stickyHeader();
	FOCheader.handlers();

	for(var i in FOCheaderOptions){
		if(i !== "topstrip"){
			if(FOCheaderOptions[i]){
				FOCheader.populateOverlay(i, "overlay-" + i);
				if(FOCheader[i]){
					if(typeof FOCheaderOptions[i] !== "boolean"){
						FOCheader[i](FOCheaderOptions[i]);
					} else {
						FOCheader[i]();
					}
				}
			} else {
				FOCheader.hideComponent(i);
			}
		}
	}

	// FOCheader.calcNavigationWidth();
})(FOCheaderOptions);

(function(){
	
	const headerContainer = document.querySelector('.foc-header-container');
	const inputSearch = document.querySelector('.foc-header-search-input');
	
	const headerNavDesktop = document.querySelector('.foc-header-nav');
	const listHeaderNavDesktop = headerNavDesktop.querySelector('ul');

	//search
	document.querySelector('.foc-header-search-submit').addEventListener('click', function() {
		if ( inputSearch.value != '' ) {
			document.querySelector('.foc-header-container form').submit();
		} else {
			if (headerContainer.classList.contains('foc-open-search')) {
				document.querySelector('.foc-header-container form').reset();
			}
			headerContainer.classList.toggle('foc-open-search');
		}
	});

	//puntinato sul menu
	const checkHeaderNavDesktop = () => {
		if (listHeaderNavDesktop.offsetWidth < listHeaderNavDesktop.scrollWidth) {
			headerNavDesktop.classList.add('with-ellipsis');
		} else {
			headerNavDesktop.classList.remove('with-ellipsis');
		}
	}

	checkHeaderNavDesktop();
	window.addEventListener('resize', checkHeaderNavDesktop);

})();